/* [data-route="/temp"] {
   
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
} */

body {
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'SpektraL';
  src: url('./fonts/Spektra-CondensedBoldBackslant.woff2') format('woff2');
}

@font-face {
  font-family: 'SpektraR';
  src: url('./fonts/Spektra-CondensedBoldItalic.woff2') format('woff2');

}

@font-face {
  font-family: 'Spektra';
  src: url('./fonts/Spektra-CondensedBold.woff2') format('woff2');
}

@font-face {
  font-family: 'BigShoulders';
  src: url('./fonts//BigShouldersDisplay-Bold.ttf');
}


@font-face {
  font-family: AcuminPro;
  src: url(./fonts/Acumin-RPro.otf);
}

*{ 
    /* Removes automatically added padding and margins */
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* outline: 2px red dotted; */
 }

:root{
  --viewport-height: 100vh;
  --mobile-max-height: 650px;
}

html{
  padding:0;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  height:100vh;
  height:100dvh;
  overflow-x: none;
  overflow-y: none;
}

body {
  width: 100%;
  max-width: 100%;
    position: relative;
    font-family: AcuminPro;
}

 #gradient-canvas {
    width: 100vw;
    height: var(--viewport-height);
    --gradient-color-4: #0d96a3; 
    --gradient-color-2: #31945d; 
    --gradient-color-1: #20926c;  
    --gradient-color-3: #0c3872;
    position: absolute;
    z-index: 0;
}

.gradient-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

#box {
    width: 100vw;
    height: var(--viewport-height);
    position: absolute;
    background: 
      repeating-radial-gradient(closest-corner at 1% 21%, rgba(255,0,255,.5), rgba(0,255,255,.5), #000 1.7%), 
      repeating-radial-gradient(closest-corner at 51% 51%, #fff, #fff, rgba(0,255,0,1) 10%);
    background-size: 55px 10px;

  }
  
  #box::before {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: 
      repeating-radial-gradient(closest-corner at 21% 21%, #fff, rgba(0,0,255,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 61% 21%, #fff, rgba(0,255,0,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 91% 51%, #fff, rgba(255,255,1,.5), rgb(055,255,255) 20%);
    background-size: 15px 13px, 12px 22px, 12px 22px;
    /* background-blend-mode: exclusion, normal;
    mix-blend-mode: exclusion; */
    content: '';
    opacity: 0.2;
  }

  .noise {
    position: fixed;
    background-image: url(./noise.png);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 10%;
    z-index: 0;
    pointer-events: none;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    height: var(--viewport-height);
    align-items: center;
    justify-content: center;
    color: #adadad;
    gap: 3.5rem;
    mix-blend-mode: color-dodge;
    position: relative;
  }

  .name img{
    max-width: 12em;
    max-height: auto;
  }
 
  .bio {
    font-family: AcuminPro;
    text-align: center;
    max-width: 45em;
    line-height: 1.5rem;
    padding: 0 1em;
  }

  .buttons{
    font-family: AcuminPro;
    padding: 0 0 0% 0;
    display: flex;
    gap: 1rem;
    padding: 0 1em;
    max-width: 100vw;
  }

  .buttons button{
    background: none;
    color: #adadad;
    border-radius:100px;
    border: 1px solid #adadad;
    width: 10em;
    padding: 1rem;
    cursor: pointer;
    font-size: 0.85em;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

  }

  .buttons button:hover{
    transform: scale(1.05); /* Increase the scale on hover */
  }

  #about-page {
    position: fixed;
    width: 90vw;
    height: 90vh;
    top: 50%;
    left: 50%;
    padding: 2em;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    animation-name: aboutSlidein;
    animation-duration: 0.2s;
    animation-fill-mode: backwards;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    gap: 2em; /* Adjust gap as necessary */
    color: white;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.5);
    backdrop-filter: blur(20px);
    border: 1px solid white;
  }
  
  #about-page.closing {
    animation-name: aboutSlideout;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes aboutSlidein {
    0% {
        transform: translate(-50%, -50%) scale(0.01);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
  }

  @keyframes aboutSlideout {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.01);
        opacity: 0;
    }
  }

  #about-page.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%) scale(0);
    pointer-events: none;
  }

  .about-container {
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    justify-content: center; /* Center content vertically */
    position: relative; /* For absolute positioning of skills-overview */
  }
  
  .about-close-button {
    position: fixed; /* Position fixed to the viewport */
    right: 2em; /* Adjust as necessary */
    top: 2em; /* Adjust as necessary */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .about-close-button img {
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .about-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }




.about-content {
    position: relative; /* Keep content above background but below bubbles */
    z-index: 1;
    max-width: 800px;
    max-height: 500px;
    width: 60vw;
    height: 60vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 4em;
}

.about-image-container {
    width: 300px;
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease-out;
    transform-style: preserve-3d;
    will-change: transform;
}

.about_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    transition: all 0.3s ease;
    transform: translateZ(50px);
}

.about-image-container:hover .about_image {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.about-title {
  font-size: 2rem;
  font-family: "BigShoulders";
  text-align: center; /* Ensure the title is centered */
}

.about-para {
  /* flex-grow: 1;  */
  /* Allows the text container to grow to fill the remaining space */
  display: flex;
  flex-direction: column;
  gap: 1em; /* Adds space between items */
  justify-content: space-between; /* Distribute the items equally vertically */
  align-items: flex-start; /* Align the content to the start horizontally */
  height: 100%; /* Ensures the text container matches the height of the image container */
  z-index: 0;  /* Keep text below bubbles */
}

.para p{
  padding-top: 10px;
  padding-bottom: 10px;
}



.about-text-content {
    border: yellow 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    height: 100%; /* Ensure the text content takes up the full height */
}

.about-buttons-container {
    align-self: flex-start;
    display: flex;
    gap: 3em;
    width: 400px;
}

.about-buttons-container-mobile{
  display: none;
}

@media  screen and (max-width: 800px) {
    .about-image-container {
        width: 200px;
        height: 200px;  /* Keep fixed height for mobile */
        margin: 20px 0;
    }
}

  
  
  

  .contact-form{
    z-index: 3;

    width: 25em;

    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    animation-name: contactSlidein;
    animation-duration: 0.2s;
    animation-fill-mode: backwards;
    opacity: 1;
    visibility: visible;

    padding: 2em;
    border-radius: 15px;
    border: 1px white solid;

    color: white;

    display: flex;
    flex-direction: column;
    gap:2em;
    align-items: center;

    background-color: rgba(200, 200, 200, 0.5);
    backdrop-filter: blur(10px);

  }

  .contact-close-button{
    color: rgb(92, 92, 92);
    position: absolute;
    right: 0em;
    background: none;
    border: none;
    text-align: left;
    font-size: 1.45em;
    font-weight: bold;
    top:0;
    cursor: pointer;
  }




  .contact-close-button img{
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }


  .contact-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  
  .contact-header{
    display: block;
    padding: 0;
    margin: 0;
  }

  .contact-form h2 {
    font-size: 1.8rem;
    font-family: "BigShoulders";
  }



  .socials img {
    width: 40px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .socials img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }

 

  .form-container{
    display: flex;
    flex-direction: column;
    gap:0.25em;
    border: 1px solid white;
    color: white;
    border-radius: 20px;

    padding: 0.75em;

    width: 100%;
  }




  .form-container input{
    /* height:2.5em; */
    width: 100%;
    text-align: start;
    color: white;
    border: none;
    background: none;
    font-family: AcuminPro;
  }


  .form-container label{
    height: 1.2rem;
    transition: 0.26s ease-in-out;
  }

  .form-container:hover label{
    font-size: 0.8em;

  }

  .message-content{
    /* height: 8em; */
    color: white;
    height: 5em;
    background: none;
    border: none;
    font-family: AcuminPro;
    resize:none;
  }

  .message-content:focus{
    outline: none;
  }

  .contact-form input:focus{
    outline: none;
  }

  /* input[type="email"]:valid{
    border: 2px solid green;
    color: green;
  } */

  
  .contact-form .send-button{
      width: 100%;
      height: 3em;
      color: white;
      background: none;
      background-blend-mode:color-dodge;


      padding:0.5em;
      border: 1px white solid;
      border-radius: 20px;

      cursor: pointer;
      transition: transform 0.3s ease; /* Adding transition for smooth effect */

      
  }


  .contact-form.closing {
    animation-name: contactSlideout;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  @keyframes contactSlidein {
    0% {
        transform: translate(-50%, -50%) scale(0.01);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
  }

  @keyframes contactSlideout {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.01);
        opacity: 0;
    }
  }

  .contact-form.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%) scale(0);
    pointer-events: none;
  }

  textarea.validityChecked, input.validityChecked{
    color:rgb(255, 102, 102);
    /* border: 2px solid blue; */
  }

  textarea.validityChecked.valid, input.validityChecked.valid{
    color: rgb(120, 219, 120);
  }


#btn-gra {
    position: relative;
    height: 3em;
    width: 100%;
    text-align: center;
    font-size: 1em;
    color: #fff;
    background: none; /* Make the background transparent */
    border: 2px solid transparent; /* Initial border size, transparent to start */
    box-sizing: border-box;
    border-radius: 30px;
    z-index: 1;
    cursor: pointer;
    overflow: hidden; /* Ensure the pseudo-elements are contained */
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

}

#btn-gra:before, #btn-gra:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px; /* Match the border-radius of the button */
    background: linear-gradient(90deg, #119675, #1a79ac, #23B684, #1c949d);
    background-size: 150%;
    z-index: -1;
}

#btn-gra:before {
    padding: 2px; /* Initial border size */
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude; /* Subtract the inner part */
    -webkit-mask-composite: destination-out; /* For Safari */
    animation: slowAnimate 20s linear infinite; /* Slow animation by default */
}

#btn-gra:after {
    filter: blur(20px);
    opacity: 0;
    animation: slowAnimate 10s linear infinite; /* Slow animation by default */
}

#btn-gra:hover:before {
    animation: animate 8s linear infinite; /* Fast animation on hover */
    padding: 3px; /* Increase the border size by 1px on hover */
}

#btn-gra:hover:after {
    opacity: 1;
    animation: animate 4s linear infinite; /* Fast animation on hover */
}

@keyframes animate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}

@keyframes slowAnimate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}






  h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0;
    line-height: 0.9em;
  }

.projects-page{
  width: 90vw;
  height: 90vh;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em;
  border-radius: 20px;
  transition: 0.3s;
  color:white; /*Title of*/
  background: none;

}

.project-open{
  /* border: 5px solid red;
  background-color: none; */
}

.project-open .top{
  filter: blur(5px);
}




.projects-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  backdrop-filter: blur(20px);
  border: 1px white solid;
  overflow: auto;
  border-radius: 20px;
}

.projects-backdrop::before {
  background: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind the content */
}

.projects-page h2 {
  font-size: 1.8rem;
  font-family: "BigShoulders";
}

.projects-page.hidden{
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center 75%;
}

.projects-container{
  color:white;
  display: grid;
  --min-column-width:300px;
  --min-row-height:300px;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr) );
  grid-template-rows: repeat(auto-fit, minmax(var(--min-row-height), 1fr));
  position: relative;
  gap: 1em;
  padding: 1em 1em 1em 1em;
  overflow-y: scroll;
}

.top{
  position: relative;
  width: 100%;
  text-align: center;
}

.top h2 {
  font-size: 40px;
}

.projects-close-button{
  position: absolute;
  right: 0em;
  background: none;
  border: none;

  top:0;
  cursor: pointer;
}

.projects-header{
  display: block;
  padding: 0;
  margin: 0;
}

.close-button{
  display: block;
  /* transform: translate(-50%, -50%); */
  width: 20px;
  /* border: 2px solid red; */
}

.projects-close-button img {
  transition: transform 0.3s ease; /* Adding transition for smooth effect */
}


.projects-close-button img:hover {
  transform: scale(1.2); /* Increase the scale on hover */
}



.project {
  width: 100%;
  min-height: var(--min-row-height);
  position: relative;
  color: black;
  mix-blend-mode: normal;
  border-radius: 15px;
  transition: transform 0.3s ease, z-index 0.3s ease; /* Smooth transition for scale and z-index */
}



.project-info{
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  

  color: white;
  z-index: 3;
}

.tags{
  display: flex;
  gap:0.5em;
  opacity: 0.9;
}

.tag{
  font-size: 0.9rem;
  width: fit-content;
  height: fit-content;
  background:#00000008;
  backdrop-filter: blur(5px);
  border: 1px white solid;
  color: rgb(255, 255, 255);
  padding:0.3em 0.7em 0.1em 0.7em;
  border-radius: 50px;
  margin: 0;
  text-wrap: nowrap;

  
}

.project-thumbnail-title{
  font-size: 1.4rem;
  text-align: center;
}

.project-thumbnail-image{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 15px;
  z-index: 1;
}




.overlay {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  z-index: 2;
  border-radius: inherit;
  /* opacity: 0.8; */
}


.project-button{
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
}



.project-button:hover{
  transition: 0.3s;
  transform: scale(1.03);
  z-index: 1;
}



.project > *{
  mix-blend-mode: normal;
}

.projects-container > :nth-child(1){
    grid-column: span 2;
    grid-row: span 1;
}

.projects-container > :nth-child(5){
  grid-column: span 2;
  grid-row: span 1;
}

.project{
  cursor: pointer;
}

.hidden-project .expanded-project {
  display: none;
}

@media screen and (max-width: 800px) {
  html{
    font-size: 14px
  }

  #about-page{
    padding-bottom: 0;
  }

  .about-content{
    /* border: 1px solid red; */
    width: 80vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    max-width: 100;

    display: grid;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 2px solid blue; */

  }

  .about-image-container{
    /* border: 2px solid red; */
    aspect-ratio: 1/1;
    height: 15%;
    width: auto;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-para{
    /* border: 2px solid green; */
    max-width: 100%;
    max-height: 75%;
    overflow-y: scroll;
    /* overflow-x: scroll; */
  }
  
  .about-buttons-container{
    display: none;
  }

  .about-buttons-container-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
    width: 100%;
    /* border: 2px solid purple; */
    gap:1em
  }

  .about-buttons-container-mobile #btn-gra{
    width: 10em;
    /* border: 2px solid grey; */
  }


  .projects-page{
    height: 95vh;
    width: 97vw;
    /* border: 2px solid red; */
  }
  .projects-container{
    grid-template-columns: 1fr;
    justify-content: center;

    --min-column-width:300px;
    --min-row-height:250px;
  }

  .projects-container .project{
    grid-row: unset;
    grid-column: unset;

  }

  .project{
    max-width: 100vw;
    /* width: 99vw; */
  }

  .projects-container{
    max-width: 100%;

  }

  .project-button{
    width: 100%;
    max-width: 100%;
    /* width: 100%; */
    /* height: 100%; */
    position: relative;
    border-radius: inherit;
  }
}

.skills-overview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 10;
}

.skill-bubble {
    position: fixed;
    pointer-events: auto;
    padding: 0.8em 1.4em;
    border-radius: 35px;
    background: rgba(255, 255, 255, 0.384);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.298);
    box-shadow: 
      0 4px 15px rgba(255, 255, 255, 0.1),
      inset 0 -2px 10px rgba(255, 255, 255, 0.1),
      inset 0 2px 10px rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    gap: 0.7em;
    font-size: 1.1em;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    min-width: max-content;
    opacity: 0;
    transition: opacity 0.5s ease var(--delay);
}

.skill-bubble.visible {
  opacity: 1;
}

.skill-icon {
  font-size: 1.4em;
  opacity: 0.9;
  transform: translateZ(20px);  /* Add some depth to the icon */
}

@media screen and (max-width: 800px) {
  .skills-overview {
    padding: 0.5em;
  }
}

@keyframes pop {
  0% { 
    transform: scale(1); 
    opacity: 1;
    backdrop-filter: blur(12px);
  }
  20% { 
    transform: scale(1.4); 
    opacity: 0.8;
    backdrop-filter: blur(16px);  /* Even more blur during expansion */
  }
  40% { 
    transform: scale(0.2); 
    opacity: 0.6;
    backdrop-filter: blur(8px);
  }
  100% { 
    transform: scale(0); 
    opacity: 0;
    backdrop-filter: blur(0px);
  }
}

.skill-bubble.popping {
  animation: pop 0.2s cubic-bezier(.17,.67,.83,.67) forwards;  /* Faster, with custom easing */
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);  /* Fade out border */
}

/* Remove the hover state */
.skill-bubble:hover {
    background: rgba(255, 255, 255, 0.384);  /* Keep the same as non-hover state */
}