:root {
    --viewport-height: 100vh;
    --card-bg: rgba(255, 255, 255, 0.1);
    --card-border: rgba(255, 255, 255, 0.15);
    --text-primary: #ffffff;
    --text-secondary: rgba(255, 255, 255, 0.7);
    --accent-color: #479df9;
    --accent-color-alt: #fc7c40;
    --shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    
    /* New stat indicator colors */
    --stat-good: rgba(46, 213, 115, 0.8);
    --stat-neutral: rgba(71, 157, 249, 0.8);
    --stat-warning: rgba(252, 124, 64, 0.8);
    --progress-bg: rgba(255, 255, 255, 0.1);
    
    /* Temperature scale colors */
    --temp-freezing: #0a1b7a;
    --temp-cold: #0055ff;
    --temp-cool: #00aaff;
    --temp-mild: #2ecc71;
    --temp-warm: #f39c12;
    --temp-hot: #e74c3c;
    --temp-extreme: #9b59b6;
    
    /* Temperature range variables - these can be set dynamically via JavaScript */
    --temp-min: -10;
    --temp-max: 40;
    --temp-range-start: 0%;  /* CSS gradient position for minimum temperature */
    --temp-range-end: 100%;  /* CSS gradient position for maximum temperature */
}

.container {
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    background: none;
}

#gradient-canvas {
    width: 100vw;
    height: var(--viewport-height);
    --gradient-color-4: #0d96a3; 
    --gradient-color-2: #31945d; 
    --gradient-color-1: #20926c;  
    --gradient-color-3: #0c3872;
    position: absolute;
    z-index: 0;
}

.gradient-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

#box {
    width: 100vw;
    height: var(--viewport-height);
    position: absolute;
    background: 
      repeating-radial-gradient(closest-corner at 1% 21%, rgba(255,0,255,.5), rgba(0,255,255,.5), #000 1.7%), 
      repeating-radial-gradient(closest-corner at 51% 51%, #fff, #fff, rgba(0,255,0,1) 10%);
    background-size: 55px 10px;

  }
  
  #box::before {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: 
      repeating-radial-gradient(closest-corner at 21% 21%, #fff, rgba(0,0,255,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 61% 21%, #fff, rgba(0,255,0,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 91% 51%, #fff, rgba(255,255,1,.5), rgb(055,255,255) 20%);
    background-size: 15px 13px, 12px 22px, 12px 22px;
    /* background-blend-mode: exclusion, normal;
    mix-blend-mode: exclusion; */
    content: '';
    opacity: 0.2;
  }

.content {
    position: relative;
    z-index: 2;
    padding: 30px;
    width: 100%;
    max-width: 1400px;
    transition: all 0.3s ease;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
}

.themeToggle {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    padding: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.themeToggle:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
}

@font-face {
    font-family: 'BigShoulders';
    src: url('../fonts//BigShouldersDisplay-Bold.ttf');
  }

.title {
    font-family: BigShoulders;
    font-weight: bold;
    font-size: 2.5em;
    color: var(--text-primary);
    text-align: center;
    margin: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.graphsContainer {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    width: 100%;
    height: calc(100vh - 200px);
}

.graphWrapper {
    background: var(--card-bg);
    padding: 25px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid var(--card-border);
    height: 450px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
}

.graphWrapper:hover {
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
}

.graphTitle {
    font-family: BigShoulders;
    font-size: 1.6em;
    margin-bottom: 20px;
    text-align: center;
    color: var(--text-primary);
    flex-shrink: 0;
    letter-spacing: 0.5px;
}

.graph {
    height: 100%;
    width: 100%;
    position: relative;
    flex: 1;
}

.bentoGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
}

.graphsRow {
    display: flex;
    gap: 30px;
    width: 100%;
}

.graphColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: transform 0.3s ease;
}

.graphColumn:hover {
    transform: translateY(-5px);
}

.statsRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    width: 100%;
}

.statsCard {
    background: var(--card-bg);
    padding: 20px;
    border-radius: 16px;
    backdrop-filter: blur(10px);
    border: 1px solid var(--card-border);
    text-align: center;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
}

.statsCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
}

.statsCard h3 {
    font-family: BigShoulders;
    font-size: 0.9em;
    margin-bottom: 8px;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.statsCard p {
    font-family: BigShoulders;
    font-size: 1.8em;
    font-weight: bold;
    color: var(--text-primary);
    margin: 0;
}

.gradient-container {
    width: 100vw;
    height: var(--viewport-height);
    position: absolute;
    z-index: 0;
}

#gradient-canvas {
    width: 100vw;
    height: var(--viewport-height);
    --gradient-color-4: #0d96a3; 
    --gradient-color-2: #31945d; 
    --gradient-color-1: #20926c;  
    --gradient-color-3: #0c3872;
    position: absolute;
    z-index: 0;
}

.noise {
    position: absolute;
    background-image: url(../noise.png);
    width: 100vw;
    height: var(--viewport-height);
    opacity: 10%;
    z-index: 0;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: var(--accent-color);
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 20px;
}

.loadingText {
    font-family: BigShoulders;
    color: var(--text-primary);
    font-size: 1.5em;
    letter-spacing: 2px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Sensor type badges */
.sensorBadge {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.8em;
    font-weight: bold;
    margin-right: 8px;
    color: white;
}

.sensorBadgeUni {
    background-color: var(--accent-color-alt);
}

.sensorBadgeHome {
    background-color: var(--accent-color);
}

/* Unified card for each measurement type */
.measurementCard {
    background: var(--card-bg);
    border-radius: 16px;
    backdrop-filter: blur(12px);
    border: 1px solid var(--card-border);
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.measurementCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.25);
}

.cardHeader {
    padding: 18px 22px;
    border-bottom: 1px solid var(--card-border);
    background: rgba(255, 255, 255, 0.03);
}

.cardTitle {
    font-family: BigShoulders;
    font-size: 1.5em;
    color: var(--text-primary);
    margin: 0;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graphSection {
    padding: 20px;
    height: 350px;
}

.statsSection {
    padding: 0 20px 20px;
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

/* Add this new rule to make single stat items stretch across the full width */
.statsGrid:has(.statItem:only-child) {
    grid-template-columns: 1fr;
}

.statsGrid .statItem:only-child {
    grid-column: 1 / -1;
}

.statItem {
    text-align: left;
    padding: 14px 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    transition: all 0.25s ease;
    border: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    position: relative;
}

.statItem:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: translateY(-2px);
    border-color: rgba(255, 255, 255, 0.15);
}

.statLabel {
    font-family: BigShoulders;
    font-size: 0.85em;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.statValue {
    font-family: BigShoulders;
    font-size: 1.4em;
    font-weight: bold;
    color: var(--text-primary);
    margin: 0;
    position: relative;
}

/* Remove the colored dots */
.statValue::before {
    content: none;
}

/* Redesigned progress bar to match the image */
.statProgress {
    position: relative;
    height: 4px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    margin: 25px 10% 25px;
    width: 80%;
    display: flex;
    align-items: center;
}

/* Add gradient backgrounds for different measurement types */
.measurementCard:nth-child(1) .statProgress {
    /* Create a background with just a neutral color first */
    background: rgba(255, 255, 255, 0.15);
    position: relative;
}

/* Temperature progress bar gradient */
.measurementCard:nth-child(1) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background-image: linear-gradient(
        to right,
        var(--temp-freezing),
        var(--temp-cold),
        var(--temp-cool),
        var(--temp-mild),
        var(--temp-warm),
        var(--temp-hot),
        var(--temp-extreme)
    ) !important;
    /* Scale the background to show full temperature range (-10 to 30, total range of 40 degrees) */
    background-size: 400% 100% !important;
    /* Position the background based on the current temperature range */
    background-position: calc((var(--temp-min) + 10) / 40 * 100%) 0 !important;
    border-radius: 2px !important;
    /* Show only the section between start and end */
    clip-path: inset(0) !important;
}

.measurementCard:nth-child(2) .statProgress {
    background: linear-gradient(to right, #d35400, #f39c12, #3498db, #2980b9);  /* Dry to wet for humidity */
}

.measurementCard:nth-child(3) .statProgress {
    background: linear-gradient(to right, #e74c3c, #f39c12, #2ecc71);  /* Low to high for pressure */
}

.measurementCard:nth-child(4) .statProgress {
    background: linear-gradient(to right, #e74c3c, #f39c12, #2ecc71);  /* Poor to good for air quality */
}

.measurementCard:nth-child(5) .statProgress {
    background: linear-gradient(to right, #3498db, #2ecc71, #f39c12, #e74c3c);  /* Low to high for gas resistance */
}

/* BME680 Temperature progress bar gradient */
.measurementCard:nth-child(2) .statItem:nth-child(1) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background-image: linear-gradient(
        to right,
        var(--temp-freezing),
        var(--temp-cold),
        var(--temp-cool),
        var(--temp-mild),
        var(--temp-warm),
        var(--temp-hot),
        var(--temp-extreme)
    ) !important;
    /* Scale the background to show full temperature range (-10 to 30, total range of 40 degrees) */
    background-size: 400% 100% !important;
    /* Position the background based on the current temperature range */
    background-position: calc((var(--temp-min) + 10) / 40 * 100%) 0 !important;
    border-radius: 2px !important;
    /* Show only the section between start and end */
    clip-path: inset(0) !important;
}

/* Humidity progress bar gradient */
.measurementCard:nth-child(2) .statItem:nth-child(2) .statProgress::before,
.measurementCard:nth-child(2) .statItem:nth-child(3) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background-image: linear-gradient(
        to right,
        var(--stat-good),
        var(--stat-neutral),
        var(--stat-warning)
    ) !important;
    /* Scale the background to show full humidity range (30 to 100, total range of 70) */
    background-size: 400% 100% !important;
    /* Position the background based on the current humidity range */
    background-position: calc((var(--humidity-min) - 30) / 70 * 100%) 0 !important;
    border-radius: 2px !important;
    /* Show only the section between start and end */
    clip-path: inset(0) !important;
}

/* Pressure gradient */
.measurementCard:nth-child(3) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background: linear-gradient(to right, #e74c3c, #f39c12, #2ecc71) !important;
    border-radius: 2px !important;
}

/* Air Quality gradient */
.measurementCard:nth-child(4) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background: linear-gradient(to right, #e74c3c, #f39c12, #2ecc71) !important;
    border-radius: 2px !important;
}

/* Gas Resistance gradient */
.measurementCard:nth-child(5) .statProgress::before {
    content: '' !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    z-index: 1 !important;
    background: linear-gradient(to right, #3498db, #2ecc71, #f39c12, #e74c3c) !important;
    border-radius: 2px !important;
}

.minMaxLabels {
    display: flex;
    justify-content: space-between;
    font-family: BigShoulders;
    font-size: 0.75em;
    color: white;
    margin-top: 0;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    opacity: 0.7;
    letter-spacing: 0.5px;
    text-shadow: none;
    z-index: 25;
    pointer-events: none;
}

/* Add specific styling for pressure and gas resistance min/max labels */
.measurementCard:nth-child(3) .minMaxLabels span,
.measurementCard:nth-child(5) .minMaxLabels span {
    transform: translateX(0) !important;
    top: 5px;
    position: relative;
}

.measurementCard:nth-child(3) .minMaxLabels span:first-child,
.measurementCard:nth-child(5) .minMaxLabels span:first-child {
    left: -55px;
    position: absolute;
}

.measurementCard:nth-child(3) .minMaxLabels span:last-child,
.measurementCard:nth-child(5) .minMaxLabels span:last-child {
    right: -55px;
    position: absolute;
}

.minMaxLabels span {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    backdrop-filter: none;
    border: none;
    text-shadow: none;
    font-weight: normal;
    position: relative;
}

.minMaxLabels span:first-child {
    text-align: left;
    transform: translateX(-200%);
}

.minMaxLabels span:last-child {
    text-align: right;
    transform: translateX(200%);
}

.progressIndicator {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    transition: all 0.2s ease;
}

.progressIndicator.current {
    background: white;
    z-index: 6;
    width: 8px;
    height: 8px;
    box-shadow: none;
}

.progressIndicator.average {
    background: var(--text-secondary);
    width: 2px;
    height: 10px;
    z-index: 5;
    border-radius: 0;
    opacity: 0.9;
    box-shadow: none;
}

.statItem:hover .progressIndicator.current {
    transform: translateY(-50%) scale(1.2);
    box-shadow: none;
}

.statItem:hover .progressIndicator.average {
    transform: translateY(-50%) scaleY(1.2);
    box-shadow: none;
}

.indicatorLabels {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    display: flex;
    pointer-events: none;
    z-index: 25;
}

.indicatorLabel {
    position: absolute;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 0;
    font-family: BigShoulders;
    font-size: 0.85em;
    letter-spacing: 0.5px;
    transition: all 0.2s ease;
        opacity: 1;
    color: white;
    text-shadow: none;
    background-color: transparent;
    border-radius: 0;
    backdrop-filter: none;
    border: none;
    z-index: 25;
}

.indicatorLabel.current {
    color: white;
    z-index: 25;
    font-weight: bold;
    top: -4px;
}

.indicatorLabel.current::after {
    content: none;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 4px;
    background: rgba(255, 255, 255, 0.4);
}

.indicatorLabel.average {
    color: white;
    top: 30px;
    z-index: 24;
    font-weight: normal;
    transform: translateX(-50%);
    font-style: normal;
    opacity: 0.7;
    font-size: 0.75em;
}

/* Add specific styling for pressure and gas resistance average indicator labels */
.measurementCard:nth-child(3) .indicatorLabel.average,
.measurementCard:nth-child(5) .indicatorLabel.average {
    top: 40px; /* Increased from the default 30px to move it down by 10px */
}

/* Adjust edge cases for left and right alignment */
.indicatorLabel.current[style*="left: 0%"],
.indicatorLabel.current[style*="left: 1%"],
.indicatorLabel.current[style*="left: 2%"],
.indicatorLabel.current[style*="left: 3%"],
.indicatorLabel.current[style*="left: 4%"],
.indicatorLabel.current[style*="left: 5%"] {
    transform: translateX(0);
}

.indicatorLabel.current[style*="left: 95%"],
.indicatorLabel.current[style*="left: 96%"],
.indicatorLabel.current[style*="left: 97%"],
.indicatorLabel.current[style*="left: 98%"],
.indicatorLabel.current[style*="left: 99%"],
.indicatorLabel.current[style*="left: 100%"] {
    transform: translateX(-100%);
}

.indicatorLabel.average[style*="left: 0%"],
.indicatorLabel.average[style*="left: 1%"],
.indicatorLabel.average[style*="left: 2%"],
.indicatorLabel.average[style*="left: 3%"],
.indicatorLabel.average[style*="left: 4%"],
.indicatorLabel.average[style*="left: 5%"] {
    transform: translateX(0);
}

.indicatorLabel.average[style*="left: 95%"],
.indicatorLabel.average[style*="left: 96%"],
.indicatorLabel.average[style*="left: 97%"],
.indicatorLabel.average[style*="left: 98%"],
.indicatorLabel.average[style*="left: 99%"],
.indicatorLabel.average[style*="left: 100%"] {
    transform: translateX(-100%);
}

/* Remove the rule that was handling overlaps since we now have them on opposite sides */
.indicatorLabel.average[style*="left: "] + .indicatorLabel.current[style*="left: "] {
    top: -4px;
}

/* Adjust hover states */
.statItem:hover .indicatorLabel.current {
    transform: translateX(-50%);
}

.statItem:hover .indicatorLabel.average {
    transform: translateX(-50%);
}

.statItem:hover .indicatorLabel.current[style*="left: 0%"],
.statItem:hover .indicatorLabel.current[style*="left: 1%"],
.statItem:hover .indicatorLabel.current[style*="left: 2%"],
.statItem:hover .indicatorLabel.current[style*="left: 3%"],
.statItem:hover .indicatorLabel.current[style*="left: 4%"],
.statItem:hover .indicatorLabel.current[style*="left: 5%"] {
    transform: translateX(0);
}

.statItem:hover .indicatorLabel.current[style*="left: 95%"],
.statItem:hover .indicatorLabel.current[style*="left: 96%"],
.statItem:hover .indicatorLabel.current[style*="left: 97%"],
.statItem:hover .indicatorLabel.current[style*="left: 98%"],
.statItem:hover .indicatorLabel.current[style*="left: 99%"],
.statItem:hover .indicatorLabel.current[style*="left: 100%"] {
    transform: translateX(-100%);
}

.statItem:hover .indicatorLabel.average[style*="left: 0%"],
.statItem:hover .indicatorLabel.average[style*="left: 1%"],
.statItem:hover .indicatorLabel.average[style*="left: 2%"],
.statItem:hover .indicatorLabel.average[style*="left: 3%"],
.statItem:hover .indicatorLabel.average[style*="left: 4%"],
.statItem:hover .indicatorLabel.average[style*="left: 5%"] {
    transform: translateX(0);
}

.statItem:hover .indicatorLabel.average[style*="left: 95%"],
.statItem:hover .indicatorLabel.average[style*="left: 96%"],
.statItem:hover .indicatorLabel.average[style*="left: 97%"],
.statItem:hover .indicatorLabel.average[style*="left: 98%"],
.statItem:hover .indicatorLabel.average[style*="left: 99%"],
.statItem:hover .indicatorLabel.average[style*="left: 100%"] {
    transform: translateX(-100%);
}

/* Remove the previous progress bar styles that used ::before */
.statProgress::before {
    /* Make sure content is set to an empty string, not 'none' */
    content: '';
}

/* Remove the custom progress bar colors for different stat types */
.statItem:nth-child(1) .statProgress::before,
.statItem:nth-child(2) .statProgress::before,
.statItem:nth-child(3) .statProgress::before,
.statItem:nth-child(4) .statProgress::before {
    /* Make sure background is not set to 'none' */
    background: inherit;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .bentoGrid {
        gap: 20px;
    }
    
    .statsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .graphSection {
        height: 300px;
    }
}

@media (max-width: 992px) {
    .bentoGrid {
        grid-template-columns: 1fr;
    }
    
    .content {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .statsGrid {
        grid-template-columns: 1fr;
    }
    
    .statItem {
        padding: 12px 14px;
    }
    
    .statLabel {
        font-size: 0.7em;
    }
    
    .statValue {
        font-size: 1.4em;
    }
    
    .graphSection {
        height: 250px;
        padding: 15px;
    }
    
    .cardHeader {
        padding: 15px;
    }
    
    .cardTitle {
        font-size: 1.4em;
    }
    
    .title {
        font-size: 2.2em;
    }
    
    .content {
        padding: 15px;
    }
    
    .bentoGrid {
        gap: 20px;
    }
    
    .statProgress {
        height: 5px;
        margin-top: 6px;
    }
} 