/* Base styles for larger screens */
@media screen and (min-width: 601px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: rgba(0, 0, 0, 0.366);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: hsl(0, 0%, 65%);
    }

    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        max-width: 100%;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: center;
        opacity: 1;
        visibility: visible;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .expanded-project.closing {
        animation-name: slideout;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }

    @keyframes slidein {
        0% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    @keyframes slideout {
        0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%) scale(0);
        pointer-events: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .expanded-project-body {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 0 1.5em;
        gap: 2.5em;
        align-items: flex-start;
    }
    
    .images-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2em;
        justify-content: center;
        padding: 0 2em;
    }

    .project-headline {
        flex: 1;
        max-width: 600px;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.1);
        aspect-ratio: 16/9;
    }

    .project-headline img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        transition: transform 0.3s ease;
    }

    .project-headline:hover img {
        transform: scale(1.02);
    }

    .project-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1.2em;
        text-align: left;
        align-items: flex-start;
        align-self: center;
    }

    .project-title {
        font-family: "BigShoulders";
        font-size: 3.5rem;
        color: white;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 100%;
        align-self: flex-start;
    }

    .project-description {
        font-family: "AcuminPro";
        font-size: 1.4rem;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1.4;
        max-width: 600px;
    }

    .project-description:first-of-type {
        font-size: 1.6rem;
        margin-bottom: 0.5em;
    }

    .content-container {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        background: linear-gradient(145deg, rgba(23, 25, 35, 0.9), rgba(30, 34, 47, 0.85));
        backdrop-filter: blur(10px);
        border-radius: 15px;
        padding: 2em;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
    }

    .content-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4em;
    }

    .top-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2em;
        width: 100%;
    }

    .intro-text {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
    }

    .project-title {
        font-size: 3.5rem;
        margin: 0;
        background: linear-gradient(90deg, #fff, #e0e0e0);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
    }

    .features-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2em;
        width: 100%;
    }

    .feature-block {
        flex: 1;
        min-width: 0;
        background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
        border-radius: 15px;
        padding: 1.8em;
        display: flex;
        flex-direction: column;
        gap: 0.8em;
        border: 1px solid rgba(255, 255, 255, 0.1);
        transition: all 0.3s ease;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        min-height: 200px;
        justify-content: flex-start;
    }

    .feature-block:hover {
        transform: translateY(-5px);
        background: linear-gradient(145deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.08));
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .feature-icon {
        font-size: 2.2rem;
        margin-bottom: 0.2em;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }

    .feature-title {
        font-family: "BigShoulders";
        font-size: 1.8rem;
        color: #fff;
        margin: 0;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .feature-description {
        font-family: "AcuminPro";
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.9);
        line-height: 1.4;
        margin: 0;
    }

    .achievements-section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2em;
        margin-top: 0;
        padding: 2em;
        background: linear-gradient(145deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.08));
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .achievement-card {
        flex: 1;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .achievement-date {
        font-family: "BigShoulders";
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .achievement-content {
        display: flex;
        align-items: center;
        gap: 1.5em;
        padding: 1.5em;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        transition: transform 0.3s ease;
    }

    .achievement-content:hover {
        transform: translateY(-5px);
        background: rgba(255, 255, 255, 0.15);
    }

    .achievement-icon {
        font-size: 2.5rem;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }

    .achievement-details h3 {
        font-family: "BigShoulders";
        font-size: 1.6rem;
        color: white;
        margin: 0 0 0.5em 0;
    }

    .achievement-details p {
        font-family: "AcuminPro";
        font-size: 1.1rem;
        color: rgba(255, 255, 255, 0.9);
        margin: 0;
        line-height: 1.4;
    }

    .achievement-divider {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.4);
        align-self: center;
    }

    .final-image-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .final-image-container {
        width: 100%;
        max-width: 1000px;
        height: 500px;
        overflow: hidden;
        border-radius: 15px;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.1);
        transition: transform 0.3s ease;
    }

    .final-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        transition: transform 0.3s ease;
    }

    .final-image-container:hover img {
        transform: scale(1.02);
    }
}
/* Base styles for larger screens */
@media screen and (max-width: 600px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: rgba(0, 0, 0, 0.366);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: hsl(0, 0%, 65%);
    }

    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 100%;
        height: 90vh;
        max-width: 100%;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 0em 1em 0em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: center;
        opacity: 1;
        visibility: visible;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes slidein {
        0% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%) scale(0);
        pointer-events: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: none;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 10px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;

        text-wrap: nowrap;

    }

    .content-container {
        width: 100%;
        background: linear-gradient(145deg, rgba(23, 25, 35, 0.9), rgba(30, 34, 47, 0.85));
        backdrop-filter: blur(10px);
        border-radius: 15px;
        padding: 2em;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
    }

    .content-layout {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2.5em;
        align-items: flex-start;
    }

    .project-headline {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
    }

    .project-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        text-align: left;
    }
}






