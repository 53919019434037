/* Base styles for larger screens */
@media screen and (min-width: 601px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: rgba(0, 0, 0, 0.366);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: hsl(0, 0%, 65%);
    }

    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        max-width: 100%;
        backdrop-filter: blur(12px);
        background: linear-gradient(
            125deg,
            rgba(20, 20, 25, 0.95) 0%,
            rgba(30, 30, 40, 0.97) 50%,
            rgba(20, 20, 25, 0.95) 100%
        );
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgba(255, 255, 255, 0.12);
        cursor: auto;
        border-radius: 20px;
        box-shadow: 
            0 20px 40px rgba(0, 0, 0, 0.15),
            inset 0 0 80px rgba(255, 255, 255, 0.02);
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: center;
        opacity: 1;
        visibility: visible;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .expanded-project.closing {
        animation-name: slideout;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
    }

    @keyframes slidein {
        0% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    @keyframes slideout {
        0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%) scale(0);
        pointer-events: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .expanded-project-body {
        display: flex;
        width: 100%;
        height: calc(100vh - 100px);  /* Adjust for header space */
        overflow-y: auto;  /* Enable vertical scrolling */
        padding: 0 1.5em;
        flex-direction: column;
        align-items: stretch;
    }
    
}
/* Base styles for larger screens */
@media screen and (max-width: 600px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: rgba(0, 0, 0, 0.366);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: hsl(0, 0%, 65%);
    }

    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 100%;
        height: 90vh;
        max-width: 100%;
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 0em 1em 0em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: center;
        opacity: 1;
        visibility: visible;
    }

    .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @keyframes slidein {
        0% {
            transform: translate(-50%, -50%) scale(0.01);
            opacity: 0;
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    .hidden-project {
        display: none;
        /* max-width: 100vw; */
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%) scale(0);
        pointer-events: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: none;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 10px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;

        text-wrap: nowrap;

    }





}

.content-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(5px);
    border-radius: 30px;
    padding: 4em;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.content-layout {
    display: flex;
    flex-direction: column;
    gap: 5em;
}

.header-section {
    text-align: center;
    margin-bottom: 2em;
}

.project-title {
    font-size: 3.5rem;
    color: white;
    margin-bottom: 0.5em;
    font-weight: 600;
}

.header-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.project-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 2em;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px rgba(41, 98, 255, 0.1);
    position: relative;
    overflow: hidden;
    animation: glowPulse 3s infinite ease-in-out;
}

.project-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.03),
        rgba(255, 255, 255, 0.01)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px) scale(1.02);
    animation: none;
    box-shadow: 0 15px 40px rgba(41, 98, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.1);
}

.project-card:hover::before {
    opacity: 1;
}

.project-icon {
    font-size: 2.5rem;
    margin-bottom: 1em;
    filter: drop-shadow(0 0 10px rgba(41, 98, 255, 0.3));
    animation: float 3s infinite ease-in-out;
}

.project-card h2 {
    font-size: 1.8rem;
    color: white;
    margin-bottom: 0.5em;
}

.project-card p {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 1.5em;
    line-height: 1.5;
}

.project-skills {
    display: flex;
    gap: 0.8em;
    flex-wrap: wrap;
}

.project-skills span {
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.04)
    );
    padding: 0.4em 1em;
    border-radius: 20px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.learnings-section {
    text-align: center;
}

.learnings-section h2 {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 2em;
}

.learnings-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.learning-item {
    text-align: left;
    padding: 2em;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px rgba(41, 98, 255, 0.1);
    animation: borderFlow 4s infinite ease-in-out;
    transition: all 0.3s ease;
}

.learning-item:hover {
    transform: translateY(-3px);
    border-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 12px 36px rgba(41, 98, 255, 0.15);
}

.learning-item span {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.learning-item h3 {
    font-size: 1.4rem;
    color: white;
    margin: 0.5em 0;
}

.learning-item p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.5;
}

.next-steps {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 25px;
    padding: 3em;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px rgba(41, 98, 255, 0.1);
    position: relative;
}

.next-steps h2 {
    font-size: 2.5rem;
    color: white;
    margin-bottom: 0.5em;
}

.next-steps p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2rem;
    line-height: 1.5;
    max-width: 600px;
    margin: 0 auto;
}

/* Remove the shine animation */
.next-steps::after {
    display: none;
}

/* Remove the keyframes since we're not using them anymore */
@keyframes shine {
    display: none;
}

.journey-timeline {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05));
    border-radius: 30px;
    padding: 3em;
    position: relative;
    overflow: hidden;
}

.journey-timeline::before {
    content: '';
    position: absolute;
    height: 2px;
    background: linear-gradient(90deg, 
        transparent 0%,
        rgba(255, 255, 255, 0.3) 20%,
        rgba(255, 255, 255, 0.3) 80%,
        transparent 100%
    );
    top: 50%;
    left: 10%;
    right: 10%;
    z-index: 0;
}

.journey-card {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    padding: 2em;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.journey-connector {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.6);
    transform: rotate(-45deg);
}

.journey-content h2 {
    font-family: "BigShoulders";
    font-size: 1.8rem;
    color: white;
    margin: 0 0 0.5em 0;
    text-align: center;
}

.journey-content p {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    line-height: 1.4;
}

.skills-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.skill-card {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    border-radius: 25px;
    padding: 2.5em;
    position: relative;
    overflow: hidden;
    border: none;
}

.skill-card::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 60%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.skill-card:hover::before {
    opacity: 1;
}

.skill-card h3 {
    font-family: "BigShoulders";
    font-size: 1.6rem;
    color: white;
    margin: 0 0 1em 0;
}

.future-section {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    border-radius: 30px;
    padding: 3em;
    position: relative;
    overflow: hidden;
}

.future-section::before {
    display: none;
}

.future-section h2 {
    font-family: "BigShoulders";
    font-size: 2.2rem;
    color: white;
    margin: 0 0 0.5em 0;
}

.future-section p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
}

/* Remove the title's after pseudo-element if it exists */
.project-title::after {
    display: none;
}

/* Remove the animation keyframes since we're not using them */
@keyframes borderAnimation {
    display: none;
}

/* Remove the animated border */
.next-steps::before {
    display: none;
}

@keyframes glowPulse {
    0% { box-shadow: 0 8px 32px rgba(41, 98, 255, 0.1); }
    50% { box-shadow: 0 8px 32px rgba(41, 98, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.1); }
    100% { box-shadow: 0 8px 32px rgba(41, 98, 255, 0.1); }
}

@keyframes borderFlow {
    0% { border-color: rgba(255, 255, 255, 0.1); }
    50% { border-color: rgba(255, 255, 255, 0.3); }
    100% { border-color: rgba(255, 255, 255, 0.1); }
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0px); }
}






